export const gql = `
query{
  entries(
    section:"projects"
    orderBy: "projectRank ASC"
  ){
    id
    title
    slug
    ... on projects_projects_Entry{
      projectDescription
      projectRank
      projectImage{
        url
        ... on projects_Asset{
          widescreenImage{
            srcset
            src
            srcWebp
            srcsetWebp
            placeholderSilhouette
            placeholderImage
            placeholderWidth
            placeholderHeight
            placeholderBox
            placeholderSvg
          }
        }
      }
    }
    seomatic(asArray: true){
      metaTitleContainer
      metaLinkContainer
      metaScriptContainer
      metaJsonLdContainer
      metaTagContainer
    }
  }
}
`
