<template>
  <div class="projects">
    <loader ref="loader" type="page"></loader>
    <div id="page-content" v-bind:class="[pageContentVisibilityState]">
      <page-header title="Projects"></page-header>
      <div class="container">
        <div class="row mb-5">
          <!--[START] Project Item-->
          <div class="col-sm-12 col-md-6 col-lg-4" v-for="(project, key) in projects" v-bind:key="key">
            <project-card
              v-bind:title="project.title"
              v-bind:description="project.projectDescription"
              v-bind:slug="project.slug"
              v-bind:image="project.projectImage[0]"
            ></project-card>
          </div>
          <!--[END] Project Item-->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { gql } from '@/gql/views/projects.js'
import Loader from '@/components/Loader.vue'
import Api from '@/api.js'
const PageHeader = () => import(/* webpackChunkName: "page-header" */ '@/components/PageHeader.vue')
const ProjectCard = () => import(/* webpackChunkName: "project-card" */ '@/components/ProjectCard.vue')

export default {
  name: 'Projects',
  metaInfo () {
      return {
          title: 'Projects | Larry Herb III Portfolio',
          meta: [
            {
              name: 'description',
              content: 'Web, Mobile Apps and, Design projects by Larry Herb.'
            },
            {
              property: 'og:title',
              content: 'Projects | Larry Herb III Portfolio'
            },
            {
              property: 'og:site_name',
              content: 'Larry Herb III Portfolio'
            },
            {
              property: 'og:description',
              content: 'Web, Mobile Apps and, Design projects by Larry Herb. '
            },
            {
              property: 'og:type',
              content: 'person'
            }
          ]
      }
  },
  components: {
    ProjectCard,
    PageHeader,
    Loader
  },
  data () {
    return {
      projects: [],
      pageContentVisibilityState: ''
    }
  },
  methods: {
    loadPage: function () {
      // Load the Page Function and Get Data
      const api = new Api({
        callback: (response) => {
          // Response Gotten
          if (response.data.entries) {
            var entries = response.data.entries
            this.projects = entries
            console.log(entries)
          }
          this.$router.onReady(() => {
            this.$refs.loader.stopAnimation()
            this.pageContentVisibilityState = 'active'
          })
        },
        query: gql,
        variables: null
      })
      console.log(api)
    }
  },
  mounted () {
    this.$router.onReady(() => {
      this.$refs.loader.startAnimation()
      this.loadPage()
    })
  }
}
</script>
